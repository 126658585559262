import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Werksverkauf"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`#####Herzlich Willkommen`}</p>
    <p>{`zu unserem Werksverkauf, immer wieder Donnerstags könnt ihr den frischen Tofu und das noch warme Okara bei uns erstehen.
Im Winter von 15:00-18:00, im Sommer von 16:00-20:00, in der Naumburgerstraße 46.
Kommt vorbei, neben den Klassikern haben wir saisonale Produkte, wie `}{`[Tofu nach Art des salzigen Käses][limeriki-tofu.de/tofu_eingelegt/]`}{`
oder `}{`[Tofu fertig für den Grill und die Pfanne][/pfannenfertigerTofu]`}{` am Start. `}</p>
    <p>{`##Wir wissen nicht, ob es der Beste Tofu in Leipzig ist, aber wir nehmen es an.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      